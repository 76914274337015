import { paths } from '$lib/config/public';
import notif from '$lib/stores/notif';
import { labels } from '$lib/defs/labels';

class ItemSet {
    static sets = new Map();
    static loaded = false;

    constructor(data) {

    }

    static async load(f=fetch) {
        async function start() {
            console.log('loading item sets');
            let sets;

            try {
                const res = await f(paths.sets, { method: 'GET' });
                sets = await res.json();
            } catch (e) {
                notif.error('Failed to load Game Objects');
                return true;
            }
    
            for (let i = 0; i < sets.length; i++)
                new ItemSet(sets[i]);
            
            return true;
        }

        if (ItemSet.loaded) return await ItemSet.loaded;

        ItemSet.loaded = start();
        ItemSet.loaded = await ItemSet.loaded;
        
        return true;
    }
}

class Projectile {
    constructor(data) {

    }
}

class Item {
    static items = new Map();
    static loaded = false;

    constructor(data) {
        this.type       = data.type;
        this.id         = data.id;
        this.name       = data.name;
        this.slot       = data.slot;
        this.desc       = `${data.desc}`.split('\n');
        this.petFamily  = data.petfam;
        this.petTier    = data.pettier;
        this.bagType    = data.bag;
        this.feedPower  = data.feed;
        this.soulbound  = data.sb;
        this.power      = data.power;
        this.xpBonus    = data.xpBonus;
        this.skin       = data.skin;
        this.tier       = data.tier;
        this.consumable = !!data.consume;
        this.dropTrade  = !!data.droptrade;
        this.labels     = data.labels || [];
        this.descExtra  = data.descex || [];

        this.isUT       = this.labels.includes('UT');
        this.isST       = this.labels.includes('ST');
        this.isShiny    = this.labels.includes('SHINY');
        this.isWeapon   = this.labels.includes('WEAPON');
        this.isAbility  = this.labels.includes('ABILITY');
        this.isArmor    = this.labels.includes('ARMOR');
        this.isRing     = this.labels.includes('RING');

        Item.items.set(this.type, this);
    }

    static async load(f=fetch) {
        async function start() {
            console.log('loading items');
            let objects;

            try {
                const res = await f(paths.objects, { method: 'GET' });
                objects = await res.json();
            } catch (e) {
                notif.error('Failed to load Game Objects');
                return true;
            }
    
            for (let i = 0; i < objects.length; i++)
                new Item(objects[i]);

            return true;
        }

        if (Item.loaded) {
            await Item.loaded;
            return true;
        }

        Item.loaded = start();
        Item.loaded = await Item.loaded;

        return true;
    }
}

const loader = async (f=fetch) => {
    await Promise.all([
        ItemSet.load(f),
        Item.load(f),
    ]);
};

export { loader, ItemSet, Item };
export default Item;